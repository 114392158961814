@import "~antd/dist/antd.css";

#portal-root {
  height: 100%;
}

/* 滚动条样式 */
::-webkit-scrollbar-thumb {
  background: #b4c0db60;
  background-clip: padding-box;
  box-shadow: none;
  min-height: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #b4c0db;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
